<template>
<div class="tabulation"  v-loading="loading" element-loading-text="加载中" element-loading-background="rgb(255, 255, 255, 0.5)">
  <el-card class="chart-card">
    <div style="margin-top: 20px;">
      <el-form
          ref="ruleFormRef"
          :model="form"
          :rules="rules"
          class="demo-ruleForm"
          :size="formSize"
          status-icon
       >
      <!-- 标题 -->
        <el-form-item label="标题名称" prop="name">
          <div class="aaaa">
            <el-input v-model="form.name"  placeholder="请填写标题" size="large"></el-input>
          </div>
        </el-form-item>
      <!-- 标题图片 -->
        <el-form-item  label="主题图片" prop="breviary">
          <el-upload action="/admin/child/saveEditorImage" list-type="picture-card" limit="1" 
            v-model="form.breviary"
            :auto-upload="true" 
            :on-change="change" 
            :on-remove="Remove"
            :class="styA==0&&styD==0?'styB':'styC'"
            :before-upload="beforeImageUpload"
            :before-remove="beforeremove"
            :on-preview="onpreview"
            :headers="header"
            :file-list="approve"
            accept=".jpeg,.png,.jpg,.bmp,.gif"
            :data='{
              imageFile: file
              }'
            name="imageFile"
            :on-success="succ"
            >
           <el-icon><Plus /></el-icon>
          </el-upload>

          <el-dialog v-model="dialogVisible">
            <img w-full :src="dialogImageUrl" alt="Preview Image" style="width:100%"/>
          </el-dialog>
          <div class="referrals">
            ( 推荐：图片尺寸 宽700px、高280px。 )
          </div>
        </el-form-item>
        <!-- 请选择组织 -->
        <el-form-item label="所属工会" prop="choose">
          <div class="aaaa">
            <el-input v-model="form.choose" readonly   placeholder="请选择所属工会" @click="visible = true"></el-input>
          </div>
        </el-form-item>
      <!-- 是否发布 -->
       <el-form-item label="是否发布" prop="are">
          <el-select v-model="form.are" placeholder="请选择是否发布" size="medium">
            <el-option label="是" value="1"  style="width:100%; text-align-last: center;" ></el-option>
            <el-option label="否" value="2" style="width:100%; text-align-last: center;"></el-option>
          </el-select>
        </el-form-item>
      <!-- 编辑器 -->
      <el-form-item prop="copyreader">
        <div class="subject">
          <span class="xingxing">*</span>
          主体内容
          <span class="referrals">( 推荐：视频体积小于40M，图片体积小于1M。 )</span>
        </div>
        <div style="border: 1px solid #ccc; width:100%">
          <Toolbar
            style="border-bottom: 1px solid #ccc"
            :editor="editorRef"
            :defaultConfig="toolbarConfig"
            :mode="mode"
          />
      
          <Editor
            style="height: 500px; overflow-y: hidden"
            v-model="valueHtml"
            :defaultConfig="editorConfig"
            :mode="mode"
            @onCreated="handleCreated"
          />
        </div>
      </el-form-item>
    </el-form>
    </div>
  <el-button type="primary" size="large" style="margin-top: 20px;" :loading="ConfirmLoading" @click="onSubmit">提交</el-button>
  </el-card>
  <div>
    <s3-layer v-model="visible" title="所属组织" :shadeClose="true" :area="['500px', '80vh']" @yes="yes">
      <el-tree
        :data="texture"
        :props="defaultProps"
        accordion
        @node-click="dendrogram"
      />
    </s3-layer>
  </div>
</div>
</template>
<script>
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { onBeforeUnmount, ref, shallowRef, onMounted, reactive,onActivated } from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import {  Plus,} from '@element-plus/icons-vue'
import * as imageConversion from 'image-conversion'
import { ElMessage } from "element-plus";
import { ZNsave,ZNredact,ZClist } from "../../utils/api"
import { useRouter,useRoute } from "vue-router";
import { useStore } from "vuex";
// import { Pictures } from '../../utils/api'
import qs from 'qs'
export default{
  name:"ZNdit",
  components:{  Editor, Toolbar, Plus, },
  setup(){
     // 选择所属工会
    let texture =ref([])
    const yes = () => {
      form.choose=options.value
      visible.value = false; 
    };
    const visible = ref(false);
    // 获取组织列表信息
    const messge=()=>{
      ZClist().then((res)=>{
        console.log(res)
        texture.value=res.data.data.manageUnionList
      })
    }
    const defaultProps = {
      children: 'unionDtoList',
      label: 'unionName',
    }
    // 获取工会code
    let gaincode=ref('')
    // 树形选择
    let options=ref('')
    let dendrogram = (node)=>{
      options.value=node.unionName
      form.choose=options.value
      gaincode.value=node.unionCode
    }





    const route = useRoute();
    // 加载
    let loading=ref(true)
    // 默认显示已上传文件
    let approve =ref([])


     let texture1 =ref([])
    const suoshu =()=>{
      ZClist().then((res)=>{
        texture1.value=res.data.data.manageUnionList
        const arr3=texture1.value.filter(function(num){
          return num.unionCode == hqcode.value
        })
        if(arr3.length==0){
          texture1.value.forEach((item)=>{
            const arr4=item.unionDtoList.filter(function(num){
             return num.unionCode == hqcode.value
            })
            if(arr4.length==0){
              item.unionDtoList.forEach((items)=>{
                const arr5 =items.unionDtoList.filter(function(nums){
                  return nums.unionCode == hqcode.value
                })
                if(arr5.length==0){
                  items.unionDtoList.forEach((ite)=>{
                    const arr6=ite.unionDtoList.filter(function(numss){
                      return numss.unionCode == hqcode.value
                    })
                    if(arr6.length==0){
                      ite.unionDtoList.forEach((it)=>{
                        const arr7=it.unionDtoList.filter(function(numsss){
                          return numsss.unionCode == hqcode.value
                        })
                        if(arr7.length==0){
                          it.unionDtoList.forEach((i)=>{
                            const arr8=i.unionDtoList.filter(function(numssss){
                              return numssss.unionCode == hqcode.value
                            })
                            if(arr8.length==0){
                              i.unionDtoList.forEach((s)=>{
                                const arr9=s.unionDtoList.filter(function(nu){
                                  return nu.unionCode == hqcode.value
                                })
                                if(arr9.length==0){
                                  s.unionDtoList.forEach((a)=>{
                                    const arr10=a.unionDtoList.filter(function(cat){
                                      return cat.unionCode == hqcode.value
                                    })
                                    if(arr10.length==0){
                                      a.unionDtoList.forEach((d)=>{
                                        const arr11=d.unionDtoList.filter(function(dog){
                                          return dog.unionCode == hqcode.value
                                        })
                                        if(arr11.length==0){
                                          d.unionDtoList.forEach((f)=>{
                                            const arr12=f.unionDtoList.filter(function(long){
                                              return long.unionCode == hqcode.value
                                            })
                                            arr12.forEach((item)=>{
                                              form.choose=arr3.unionName=item.unionName
                                              gaincode.value=arr3.unionCode=item.unionCode
                                            })
                                          })
                                        }
                                        arr11.forEach((item)=>{
                                          form.choose=arr3.unionName=item.unionName
                                          gaincode.value=arr3.unionCode=item.unionCode
                                        })
                                      })
                                    }
                                    arr10.forEach((item)=>{
                                      form.choose=arr3.unionName=item.unionName
                                      gaincode.value=arr3.unionCode=item.unionCode
                                    })
                                  })
                                }
                                arr9.forEach((item)=>{
                                  form.choose=arr3.unionName=item.unionName
                                  gaincode.value=arr3.unionCode=item.unionCode
                                })
                              })
                            }
                            arr8.forEach((item)=>{
                              form.choose=arr3.unionName=item.unionName
                              gaincode.value=arr3.unionCode=item.unionCode
                            })
                          })
                        }
                        arr7.forEach((item)=>{
                          form.choose=arr3.unionName=item.unionName
                          gaincode.value=arr3.unionCode=item.unionCode
                        })
                      })
                    }
                     arr6.forEach((item)=>{
                       form.choose=arr3.unionName=item.unionName
                       gaincode.value=arr3.unionCode=item.unionCode
                    })
                  })
                }
                console.log(arr5)
                arr5.forEach((item)=>{
                form.choose=arr3.unionName=item.unionName
                gaincode.value=arr3.unionCode=item.unionCode
                })
              })
            }else{
              arr4.forEach((item)=>{
              form.choose=arr3.unionName=item.unionName
              gaincode.value=arr3.unionCode=item.unionCode
              })
            }
          })
        }else{
          arr3.forEach((item)=>{
          form.choose=arr3.unionName=item.unionName
          gaincode.value=arr3.unionCode=item.unionCode
          })
        }
        loading.value=false
        // cat.value='auto'
        // console.log(res)
      })
    }











    // 获取组织code
    let hqcode=ref('')
    // 子女托管编辑获取信息
    let dact =()=>{
      let data={
        id:sessionStorage.getItem('offspring')
      }
      ZNredact(qs.stringify(data)).then((res)=>{
        console.log(res)
        if(res.data.code==0){
          loading.value=false
          suoshu()
          // 所属工会
          hqcode.value=res.data.data.childCustodyShowInfo.unionCode
          valueHtml.value=res.data.data.childCustodyShowInfo.content
          form.name=res.data.data.childCustodyShowInfo.title
          if(res.data.data.childCustodyShowInfo.isEnable==2){
            form.are='否'
          }else if(res.data.data.childCustodyShowInfo.isEnable==1){
            form.are='是'
          }
          if(res.data.data.childCustodyShowInfo.imageInfo==null||res.data.data.childCustodyShowInfo.imageInfo==''){
            styD.value=0
          }else{
            styD.value=1
            let data={
              url:res.data.data.childCustodyShowInfo.imageInfo
            }
            approve.value.push(data)
            form.breviary=res.data.data.childCustodyShowInfo.imageInfo
          }
        }
      })
    }
    let data = ref(null);
    // 按钮加载
    const ConfirmLoading=ref(false)
    
    // 上传图片
    const header=ref({
      timeout: 10000,
      'accessToken':sessionStorage.getItem('token')
    })
    // 获取上传图片成功地址
    let location= ref('')
    const succ=(res)=>{
      location.value=res.data.url.substring(57)
      console.log(res.data.url.substring(57))

    }
    // 自定义上传
    const httpreqst=(param)=>{
      console.log(param)
      console.log(param.file.name)
      data.value ={
        imageFile:param.file.namefileList
      }
      // Pictures(data).then((res)=>{
      //   console.log(res)
      // })
    }
    const styA = ref(0)
    const styD=ref(1)
    
    const change=(res,fileList)=>{
      // console.log(res)
      styA.value=fileList.length
      form.breviary=fileList
      // console.log(fileList)
    }
    const beforeImageUpload=(File)=>{
      let isLt2M = File.size / 1024 / 1024 < 1 
      return new Promise((resolve)=>{
        if (isLt2M) {
          resolve(File)
        }
        imageConversion.compressAccurately(File, 930).then((res) => {
          resolve(res);
          // data.value = File.name
        });
      })
    }
    // 图片放大
    const dialogVisible=ref(false)
    let dialogImageUrl=ref('')
    const onpreview = (File)=>{
      dialogVisible.value=true
      dialogImageUrl.value=File.url
      console.log(File)
    }
    // 删除图片
    const beforeremove = (File,fileList)=>{
      console.log(File)
      console.log(fileList)
      styD.value=0
      styA.value=0
    }
    const Remove =(file)=>{
      console.log(file)
    }
    // 是否发布
    let fabu =ref(null)

    // 提交 按钮
     // 关闭当前页
    const store = useStore();
    const current=ref('')
    const expression = ref([])
    const Indexs=ref(null)


    const router = useRouter();
    const onSubmit =()=>{
      if(valueHtml.value=='<p><br></p>'){
        form.copyreader=''
      }else{
        form.copyreader=valueHtml.value
      }
      console.log(gaincode.value)
      // console.log('标题',form.name)
      // console.log('缩略图地址',location.value)
      // console.log('是否发布',form.are)
      // console.log('编辑器内容',form.copyreader)
      if(form.are=='否'){
        fabu.value=2
      }else if(form.are=='是'){
        fabu.value=1
      }else if(form.are==1){
        fabu.value=1
      }else if(form.are==2){
        fabu.value=2
      }
      ruleFormRef.value.validate((valid)=>{
        console.log(valid)
        let data ={
          id:sessionStorage.getItem('offspring'),
          // 标题
          title:form.name,
          // 图片
          imageInfo:location.value,
          // 是否公开
          isEnable:fabu.value,
          // 编辑器内容
          content:form.copyreader,
          unionCode:gaincode.value
        }
        if(valid==true){
          ConfirmLoading.value=true
          ZNsave(qs.parse(data)).then((res)=>{
            console.log(res)
            if(res.data.code==0){
              ElMessage({
                message: '提交成功',
                type: "success",
                center: true,
              });
              setTimeout(()=>{
                current.value=route.path
                  expression.value=store.state.tagsList

                  expression.value.forEach((item,index)=>{
                    if(current.value==item.path){
                      Indexs.value=index
                    }
                  })
                  store.state.tagsList.splice(Indexs.value,1)
                router.push({ name: 'children', params: { userId: 1 }});
                ConfirmLoading.value=false
              },500)
            }else if(res.data.code!==0){
              ElMessage({
                message: res.data.msg,
                type: "error",
                center: true,
              });
            }
          })
        }else{
          ElMessage({
            message: "提交失败",
            type: "error",
            center: true,
          });
          ConfirmLoading.value=false
        }
      })
    }
 // 编辑器实例，必须用 shallowRef
    const editorRef = shallowRef()
 
    // 内容 HTML
    const valueHtml = ref('')
 
    // 模拟 ajax 异步获取内容
    let translate=ref(2)

    onActivated(()=>{
      translate.value=route.params.UserId
      if(translate.value==1){
        loading.value=true
        approve.value=[]
        dact()
        messge()
      }

    })
      // onMounted(() => {
      //   approve.value=[]
      //   dact()
      //   messge()
      // })
  
    const toolbarConfig = {
      toolbarKeys: [
        'bold', // 加粗
        'italic', // 斜体
        'through', // 删除线
        'underline', // 下划线
        'bulletedList', // 无序列表
        'numberedList', // 有序列表
        'color', // 文字颜色
        'fontSize', // 字体大小
        'lineHeight', // 行高
        'uploadImage', // 上传图片
        'delIndent', // 缩进
        'indent', // 增进
        'deleteImage',//删除图片
        'justifyCenter', // 居中对齐
        'justifyJustify', // 两端对齐
        'justifyLeft', // 左对齐
        'justifyRight', // 右对齐
        'undo', // 撤销
        'redo', // 重做
        'clearStyle', // 清除格式
        'uploadVideo',
      ],
      excludeKeys: [
        'insertImage', // 网络图片
        'bgColor', // 背景色
        'blockquote', // 引用
        'codeBlock', // 代码段
        'emotion', // 表情
        'fontFamily', // 字体
        'headerSelect', // 标题 
        'fullScreen', // 全屏
      ],
    }
    // 富文本上传图片地址
    // let fils=ref('')
    // placeholder: '请输入内容...',
    const editorConfig = { MENU_CONF: {} }
      // 视频上传
      editorConfig.MENU_CONF['uploadVideo'] = {
      // 上传视频的配置
      // 接口
      server:'/admin/child/saveEditorImage',
      // 请求头
      headers: {
        timeout: 10000,
        'accessToken':sessionStorage.getItem('token')
      },
      maxFileSize: 41943040, // 1M
      //上传字段 
      fieldName: 'imageFile',
      maxNumberOfFiles: 1,
      // 上传前回调
      onBeforeUpload(file) {
        console.log(file)
        let fileObj = Object.values(file)[0].data
        console.log(fileObj)
        // 压缩图片
        let isLt2M = Object.values(file)[0].data.size
        if(isLt2M<41943040){
          return true
        }else if(isLt2M>41943040){
          ElMessage({
            message: '视频大小不能超过40MB,请重新上传',
            type: "warning",
            center: true,
          });
          return false
        }
      },
      // 上传成功后回调
      onSuccess(file, res) {
        console.log(file)
        console.log(res)
      },
      // 上传失败回调
      onError(file, err, res) {
        console.log(file)
        console.log(err)
        console.log(res)
        if(file.size>41943040){
           ElMessage({
            message: '视频大小不能超过40MB,请重新上传',
            type: "warning",
            center: true,
          });
        }else{
          ElMessage({
            message: '上传失败，请稍后再试',
            type: "warning",
            center: true,
          });
        }
      },
    }


    // 图片上传
    editorConfig.MENU_CONF['uploadImage'] = {
    // 上传图片的配置 
    // 接口
      server:'/admin/child/saveEditorImage',
    // 请求头
      headers: {
        timeout: 10000,
        'accessToken':sessionStorage.getItem('token')
      },
      maxFileSize: 1 * 1024 * 1024, // 1M
    //上传字段 
      fieldName: 'imageFile',
      // meta: {
      //   imageFile:fils
      // },
      // 最多可上传几个文件，默认为 100
      maxNumberOfFiles: 1,
      // 上传前回调
      onBeforeUpload(file) {
        console.log(file)
        let fileObj = Object.values(file)[0].data
        console.log(fileObj)
        // 压缩图片
        let isLt2M = Object.values(file)[0].data.size
        if(isLt2M<1048576){
          return true
        }else if(isLt2M>1048576){
          ElMessage({
            message: '图片大小不能超过1MB,请重新上传',
            type: "warning",
            center: true,
          });
          return false
        }
      },
      // 上传成功后回调
      onSuccess(file, res) {
        console.log(file)
        console.log(res)
      },
      // 上传失败回调
      onError() {
        ElMessage({
            message: '图片大小不能超过1MB,请重新上传',
            type: "warning",
            center: true,
          });
      },
    }
 
    // 组件销毁时，也及时销毁编辑器
    onBeforeUnmount(() => {
      const editor = editorRef.value 
      if (editor == null) return
      editor.destroy()
    })
 
    const handleCreated = (editor) => {
      editorRef.value = editor // 记录 editor 实例，重要！
      // console.log(editor)
    }
    // 标题验证
    const ruleFormRef = ref(null)
    const form = reactive({
      name:"",
      sex:"",
      copyreader:"",
      are:'',
      bywork:'',
      choose:'',
      breviary:''
    })
    // 验证表单提示
    const rules ={
      breviary:[
        { required: true, message: "请上传主题图片", trigger: "change" },
      ],
      choose:[
        { required: true, message: "请选择所属工会", trigger: "change" },
      ],
      are:[
        { required: true, message: "请选择是否发布", trigger: "change" },
      ],
      copyreader:[
        { required: true, message: "请填写内容", trigger: "change" },
      ],
      name:[
        { required: true, message: "请填写标题", trigger: "blur" },
      ],
      sex:[
        { required: true, message: "请选择分类", trigger: "change" },
      ],
    }
    return{
      visible,
      defaultProps,
      yes,
      texture,
      dendrogram,



      loading,
      approve,
      header,
      succ,
      data,
      // 按钮加载
      ConfirmLoading,
      // 上传图片
      httpreqst,
      dialogVisible,
      dialogImageUrl,
      onpreview,
      beforeremove,
      Remove,
      styA,
      styD,
      change,
      beforeImageUpload,
      // handlePictureCardPreview,
      // 编辑器
      onSubmit,
      editorRef,
      valueHtml,
      mode: 'default',
      toolbarConfig,
      editorConfig,
      handleCreated,
      // 标题验证
      ruleFormRef,
      form,
      rules
    }
  }
 }
</script>
<style lang='scss' scoped>
::v-deep .chart-card::-webkit-scrollbar{
  display:none
}
::v-deep .chart-card{
  height: calc(100vh - 145px);
  overflow: auto;
}
::v-deep .subject{
  margin-left: 3px;
  font-size: var(--el-form-label-font-size);
  font-weight: bold;
  margin-bottom: 5px;
  color: var(--el-text-color-regular);
  .xingxing{
    color: var(--el-color-danger);
  }
}
// .w-e-text-container [data-slate-editor] p{
//   margin-top: 22px;
//   margin-bottom: 22px;
// }
::v-deep .el-button{
  width: 100px;
  height: 40px;
  font-size: 16px;
}
::v-deep .el-form-item__content{
  align-items: flex-end;
}
::v-deep .referrals{
  color: #cccccc;
  margin-left: 5px;
  font-weight: 500;
}
::v-deep .styB .el-upload--picture-card{
  width:110px;
  height:110px;
  line-height:110px;
}
::v-deep .styC .el-upload--picture-card{
  display:none;   
}
::v-deep .el-form-item{
  align-items: center;
}
::v-deep .aaaa{
  width: 50%;
}
::v-deep .el-card__body {
  padding: 0;
}
::v-deep .el-main{
  overflow: auto !important;
}
::v-deep .el-card.is-always-shadow{
  width: 95%;
  margin: auto;
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
::v-deep .tabulation {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  overflow: auto !important;
}
</style>
<style scoped>
::v-deep .upload-demo{
  display: flex;
  width: 100%;
}
::v-deep .upload-demo .el-upload-list{
  display: flex;
  width: 50%;
}
::v-deep .el-input{
  height: 44px !important;
}
::v-deep .el-form-item__label{
  width: 80px !important;
  font-weight: bold;
}
</style>
